import React from "react"
import Message from "../components/Message"
import Loader from "../components/Loader"
import Layout from "../components/layout"
import Axios from "axios"

const usersAPI = process.env.API_USERS

class Confirm extends React.Component {

    constructor(props) {
        super(props)
        this.state = { 
            isLoading: true,
            message: undefined,
        }
    }

    componentDidMount = () => {
        this.confirmUser(this.props["*"])
    }
        
    confirmUser = (confirmationCode) => {
        Axios.post(`${usersAPI}/confirm`,
            {
                confirmation: confirmationCode
            }
        )
        .then(() => {
            this.setState({ 
                message: {
                    type: "success",
                    text: "Your email has been confirmed, an Administrator will now review your request"
                },
                isLoading: false
            })
        })
        .catch(error => {
            this.setState({
                message: {
                    type: "danger",
                    text: error.response.data.message
                },
                isLoading: false
            })
        })
    }

    render() {
        const {message, isLoading} = this.state
        const {location} = this.props
        if(isLoading === true) {
            return(
                <Loader />
            )
        } else {
            return(
                <Layout location={location}>
                    <Message type={message.type} message={message.text} />
                </Layout>
            )
        }
    }
}

export default Confirm